<template>
  <!-- eslint-disable max-len -->
  <svg
    viewBox="0 0 23.8 23.8">
    <path
      d="M0.3,23.5c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3l10.2-10.2l10.2,10.2c0.2,0.2,0.4,0.3,0.7,0.3
      s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L13.2,11.9L23.5,1.6c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L11.9,10.5L1.6,0.3c-0.4-0.4-1-0.4-1.4,0
      s-0.4,1,0,1.4l10.2,10.2L0.3,22.1C-0.1,22.5-0.1,23.1,0.3,23.5z">
    </path>
  </svg>
</template>
